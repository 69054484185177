import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Global } from "@emotion/core";
import { rgb } from "d3";

export default (props) => {
  const { bgColor, bgImage, compColor } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "pic.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <Global
            styles={(theme) => ({
              html: {
                height: `100%`
              },
              body: {
                height: `100%`,
                backgroundColor: `${bgColor}`,
                backgroundImage: bgImage ? `url("${imageData.src}")` : `none`,
                backgroundSize: `cover`,
                backgroundPosition: `center`,
              },
              a: {
                backgroundImage:
                  `linear-gradient(90deg, transparent 0%, transparent 9.9%, ` +
                  compColor +
                  ` 10%,` +
                  rgb(compColor).brighter() +
                  ` 200%)`,
              },
            })}
          />
        );
      }}
    />
  );
};