import React from "react";
import { Link } from "gatsby";

export default (props) => {
  const { compColor } = props;
  return (
      <nav>
    <Link to="/" id="homeLink">
      <svg
        id="moniker"
        width="70px"
        height="70px"
        viewBox="0 0 120 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Wynand Kok</title>
        <g
          id="Signus"
          stroke="none"
          strokeWidth="4"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group"
            transform="translate(60.000000, 48.000000) rotate(-360.000000) translate(-60.000000, -48.000000) translate(-13.000000, -8.000000)"
            strokeWidth="6"
          >
            <path
              d="M77.135602,34.3239876 C76.8259897,33.1384364 76.2106037,32.0549237 75.3509618,31.1817643 C72.6386997,28.4268527 68.2066797,28.3922786 65.4517681,31.1045407 L31.6412316,64.3916488 C30.7374043,65.2814837 30.092328,66.4001683 29.7749336,67.6281629 C28.8074994,71.371153 31.0575321,75.1897064 34.8005222,76.1571406 L80.3807923,87.9380725 C81.535494,88.2365232 82.7473024,88.2349968 83.9012486,87.9336382 C87.6417898,86.9567781 89.8821959,83.1325687 88.9053357,79.3920276 L77.135602,34.3239876 Z"
              id="L"
              stroke={compColor.toString()}
              strokeOpacity="1"
              transform="translate(56.530733, 55.968647) rotate(-315.000000) translate(-56.530733, -55.968647) "
            ></path>
            <path
              d="M110.074626,34.3239876 C109.765014,33.1384364 109.149628,32.0549237 108.289986,31.1817643 C105.577724,28.4268527 101.145704,28.3922786 98.3907925,31.1045407 L64.580256,64.3916488 C63.6764287,65.2814837 63.0313524,66.4001683 62.713958,67.6281629 C61.7465238,71.371153 63.9965564,75.1897064 67.7395465,76.1571406 L113.319817,87.9380725 C114.474518,88.2365232 115.686327,88.2349968 116.840273,87.9336382 C120.580814,86.9567781 122.82122,83.1325687 121.84436,79.3920276 L110.074626,34.3239876 Z"
              id="R"
              stroke={compColor.toString()}
              strokeOpacity="1"
              transform="translate(89.469757, 55.968647) rotate(-315.000000) translate(-89.469757, -55.968647) "
            ></path>
          </g>
        </g>
      </svg>
    </Link>
    <Link activeClassName="active" to="/about">About</Link>
    </nav>
  );
};
