import React, { useState, useEffect, useRef } from "react";
import scrollsectionStyles from "./scrollsection.module.css";

export default function ScrollSection(props) {
  const domRef = useRef();
  const [headingOpacity, setHeadingOpacity] = useState(true);
  const [countOpacity, setCountOpacity] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    const hOpacity =
      domRef.current.offsetTop > position
        ? (-domRef.current.offsetTop + position + 270) / 100
        : (130 + domRef.current.offsetTop - position) / 100;
    const cOpacity =
      domRef.current.offsetTop > position
        ? (-domRef.current.offsetTop + position + 170) / 100
        : (domRef.current.scrollHeight +
            domRef.current.offsetTop -
            100 -
            position) /
          100;
    setHeadingOpacity(hOpacity);
    setCountOpacity(cOpacity);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("onload", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      ref={domRef}
      className={scrollsectionStyles.scrollSection}
      style={{ margin: `3rem auto`, maxWidth: `90%` }}
    >
      <h2
        style={{
          opacity: headingOpacity,
        }}
      >
        {props.heading}
      </h2>
      {props.children}
      <div className={scrollsectionStyles.sectionDivider} style={{
        opacity: countOpacity
      }}></div>
      <h3
        className="section-count"
        style={{
          opacity: countOpacity,
          bottom: `3.3986rem`,
        }}
      >
        {props.sectionCount}
      </h3>
    </section>
  );
}
